<template>
  <section
    :class="`common-container wiki-commom-type-${domainConfig['designType']} detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar currentPage="detail" />
    <div class="common" :class="`common_${deviceType}`">
      <div class="wiki-banner" :style="{
         backgroundImage: `url(/background/type-${domainConfig['bg']}.png)`,
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat'
       }">
        <div class="wiki-banner-head">
          About
        </div>
      </div>

      <div class="flex-auto common-half-pc common-half-pc-flex">
        <div class="common-space-between">
          <div class="common-item">
            <div class="common-title">
              About Us
            </div>
            <div class="common-content" v-html="domainConfig['ABOUTUS']" />
          </div>
          <div class="common-item">
            <div class="common-title">
              Contact Us
            </div>
            <div class="common-content">
              <a class="common-href"
                :href="`mailto:${domainConfig['IAMEMAIL']}`">{{ domainConfig['IAMEMAIL'] }}</a>
            </div>
          </div>
        </div>
        <footer>
          Copyright © 2024 {{ domainConfig['IAmURL'] }}. All Rights Reserved.
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import HeadBar from '@/pages/components/head_pc.vue';

import '@/css/home.scss';

export default {
  name: 'About',
  components: {
    HeadBar,
  },
  mixins: [common],
  computed: {
    ...mapGetters([
      "domainConfig"
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
